import React from 'react';
import { Layout } from 'antd';
import { AuthAction, Footer } from '../components';

const AuthPage = () => (
  <Layout style={{ height: '100vh' }}>
    <Layout.Content>
      <AuthAction />
    </Layout.Content>
    <Footer />
  </Layout>
);

export default AuthPage;
